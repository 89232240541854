export default {
  data () {
    return {
      height: null,
      id: Math.ceil(Math.random(2) * 1000)
    }
  },

  props: {
    width: {
      type: Number,
      required: false
    },

    top: {
      type: Number,
      required: false
    },

    left: {
      type: Number,
      required: false
    },

    fill1: {
      type: String,
      required: false
    },

    fill2: {
      type: String,
      required: false
    },

    fill3: {
      type: String,
      required: false
    },

    stroke1: {
      type: String,
      required: false
    }
  },

  computed: {
    topPx () {
      return this.top && (this.top + 'px')
    },

    leftPx () {
      return this.left && (this.left + 'px')
    }
  }
}
