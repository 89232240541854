<template>
<span>
  <svg viewBox="0 0 744.09448819 1052.3622047" :width="width" :height="height" :style="{top: topPx, left: leftPx}" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <filter id="f">
    <feGaussianBlur stdDeviation="15.60143"/>
    </filter>
    <filter id="e" x="-.33923" y="-.07289" width="1.6785" height="1.1458">
    <feGaussianBlur stdDeviation="10.5"/>
    </filter>
    <filter id="d" x="-.18344" y="-.08916" width="1.3669" height="1.1783">
    <feGaussianBlur stdDeviation="14.21514"/>
    </filter>
    <filter id="c" x="-.21841" y="-.08273" width="1.4368" height="1.1654">
    <feGaussianBlur stdDeviation="11.81794"/>
    </filter>
    <linearGradient id="b" x1="428.13" x2="403.67" y1="293.97" y2="362.42" gradientUnits="userSpaceOnUse">
    <stop stop-color="#404040" offset="0"/>
    <stop stop-color="#404040" stop-opacity="0" offset="1"/>
    </linearGradient>
    <linearGradient id="a" x1="422.54" x2="414.19" y1="290.2" y2="365.77" gradientUnits="userSpaceOnUse">
    <stop stop-color="#525252" offset="0"/>
    <stop stop-color="#525252" stop-opacity="0" offset="1"/>
    </linearGradient>
  </defs>
  <path d="m514.29 165.25c159.15 25.501 110.07 200.32 86.092 264.15-25.282 67.285-47.899 196.23-23.14 251.93s49.781 165.77 24.584 176.49c-44.068 18.748-54.066-38.619-95.26-48.917s-12.873 69.514-64.365 72.089-38.619-77.238-82.387-97.835-92.685 77.238-139.03 54.066-12.873-90.111-25.746-115.86-56.641 38.619-92.685-12.873c-25.056-35.794 69.106-101.2 97.835-144.18s80.79-136.71 99.812-226.78c18.328-86.787 54.72-197.85 214.29-172.28z" filter="url(#f)" opacity=".75" stroke="#000" stroke-width=".90111"/>
  <path d="m506.29 151.25c153.43 36.93 110.07 200.32 86.092 264.15-25.282 67.285-47.899 196.23-23.14 251.93s49.781 165.77 24.584 176.49c-44.068 18.748-54.066-38.619-95.26-48.917s-12.873 69.514-64.365 72.089-38.619-77.238-82.387-97.835-92.685 77.238-139.03 54.066-12.873-90.111-25.746-115.86-56.641 38.619-92.685-12.873c-25.056-35.794 69.106-101.2 97.835-144.18s80.79-136.71 99.812-226.78c18.328-86.787 57.587-210 214.29-172.28z" :fill="fill1" :stroke="stroke1" stroke-width="2"/>
  <path transform="matrix(1.3086 .64264 -.65481 1.2843 108.51 -353.14)" d="m431.43 328.08a14.286 32.857 0 1 1 -28.571 0 14.286 32.857 0 1 1 28.571 0z" stroke="url(#a)" stroke-linecap="round" stroke-linejoin="round" stroke-width="4.7809"/>
  <path transform="matrix(1.055 .13775 -.10265 1.4157 135.16 -157.58)" d="m431.43 328.08a14.286 32.857 0 1 1 -28.571 0 14.286 32.857 0 1 1 28.571 0z" stroke="url(#b)" stroke-linecap="round" stroke-linejoin="round" stroke-width="5.6445"/>
  <path d="m483.14 684.93c0-45.714 48.571-194.29 48.571-194.29s-5.7143 97.143-5.7143 140 31.429 205.71 31.429 205.71-34.286-25.714-40-40c-27.386-7.2853-35.948-24.105-34.286-111.43z" fill="#bfbfbf" filter="url(#e)" opacity=".25"/>
  <path d="m308.86 679.22c45.714-125.71 57.143-214.29 57.143-214.29s26.075 85.437 11.429 125.71c-22.857 62.857-17.143 165.71-17.143 165.71s-40 8.5714-97.143 51.429c-26.028-4.4587-4.5192-44.105 45.714-128.57z" fill="#bfbfbf" filter="url(#c)" opacity=".25"/>
  <path d="m189.46 620.63c85.482-99.712 116.79-286.17 116.79-286.17s-7.1633 100.33-14.573 142.54c-14.94 85.107-48.942 171.58-59.969 174.89 0 0-44.589 46.809-97.143 65.194-26.028-4.4587-21.344-45.635 54.891-96.452z" fill="#bfbfbf" filter="url(#d)" opacity=".25"/>
  </svg>
</span>
</template>

<script>
import ThemeMixin from '../theme_mixin'

export default {
  name: 'ghost',

  mixins: [ThemeMixin]
}
</script>
